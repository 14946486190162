.AppHeaderComponent_main {
  position: relative;
  min-height: 80px;
  max-height: 80px;
  display: flex;
  width: 100%;
  background: white;
  z-index: 7;
}

.AppHeaderComponent_main_first_tr {
  flex: 1;
  display: flex;
  align-items: center;
}
.AppHeaderComponent_main_second_tr {
  display: flex;
  align-items: center;
}

.AppHeaderComponent_main_right_button {
  padding-right: 20px;
}

.AppHeaderComponent_main_bot_name {
  padding-left: 20px;
}

.AppHeaderComponent_main_bot_name span {
  font-family: var(--main-font);
  font-size: 18px;
  font-weight: 200;
  color: #212121;
}

.new_bot_button {
  /* Style for "Прямоуголь" */
  width: 140px;
  height: 40px;
  border-radius: 4px;
  background-color: #006cff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.new_bot_button span {
  font-family: var(--main-font);
  color: white;
  font-size: 12px;
  font-weight: 500;
}

.NotificationComponent_main {
  display: flex;
  align-items: center;
}

.NotificationComponent_view {
  margin-left: 10px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.AppHeaderComponent_main_first {
  display: flex;
  align-items: center;
}

.NotificationComponent_pulsel_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  background-color: rgba(255, 111, 97, 0.38);
  border-radius: 50%;
}

.NotificationComponent_pulsel_wrapper_animation {
  animation-duration: 0.5s;
  animation-name: puls;
  animation-iteration-count: infinite;
}

@keyframes puls {
  0% {
    width: 20px;
    height: 20px;
  }

  100% {
    width: 30px;
    height: 30px;
  }
}

.NotificationComponent_pulsel {
  /* Style for "Эллипс 1 к" */
  width: 20px;
  height: 20px;
  background-color: #ff6f61;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.NotificationComponent_number span {
  font-size: 10px;
  font-family: var(--main-font);
  color: white;
  font-weight: 500;
}

.NotificationComponent_number {
  display: flex;
  align-items: center;
  justify-content: center;
}

.NotificationComponent_main_tr {
  display: flex;
  align-items: center;
}

.NotificationComponent_img {
  display: flex;
  align-items: center;
  justify-content: center;
}

.AppHeaderComponent_main_notification_wrapper {
  display: flex;
  align-items: center;
}

.AppHeaderComponent_main_notification_education {
  margin-right: 20px;
}

.AppHeaderComponent_main_notification_jingle {
  margin-right: 10px;
}
.test_button_group_bot_select_option {
  font-family: var(--main-font);
  font-weight: 500;
  font-size: 12px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 20px;
  box-shadow: 0 0 black;
}

.test_button_group_bot_select_option_title {
  font-family: var(--main-font);
  font-weight: 600;
  font-size: 14px;
  color: black;
}
.test_button_group_bot_select_option img {
  margin-right: 10px;
}
.test_bots {
  margin-left: 20px;
}
.beta_v_notification_wrapper {
  display: flex;
  margin: 0 20px;
  justify-content: space-between;
  align-items: center;
  background: #fafafa;
  border-radius: 4px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
}
.AppHeaderComponent_main_navbar_burger {
  display: none;
}
.beta_v_notification_text {
  display: flex;
  padding: 0 5px;
  color: #757575;
  font-size: 12px;
  font-family: var(--main-font);
  font-weight: 500;
}
.beta_v_notification_wrapper a {
  text-decoration: none;
}
.beta_v_notification_button {
  border: none
}

.AppHeaderComponent_main_first_trial {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 30px;
  margin: 5px 20px 10px 20px;
  background: linear-gradient(108.02deg, #E83939 0%, #D90000 100%);
  border-radius: 10px;
}

.AppHeaderComponent_main_first_trial p{
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 133.5%;
  color: #ffffff;
}

.AppHeaderComponent_main_first_trial a{
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 133.5%;
  color: #ffffff;
  text-decoration: none;
  margin: 0px 0px 0px 10px;
}

#trial_days_header {
  font-weight: bold;
}

.AppHeaderComponent_main_first_trial_link {
  display: flex;
  width: fit-content;
}

.AppHeaderComponent_main_first_trial_title {
  display: flex;
  max-width: 70%;
}

@media(max-width: 1024px) {
  .AppHeaderComponent_main_first_trial {
    height: 100%;
    padding: 10px 20px;
  }
  .AppHeaderComponent_main {
    padding: 0px 0px 10px 0px;
    /* TODO(m-nny): debug difference with prod */
    /* min-height: auto !important; */
  }
}


@media(min-width: 414px) and (max-width: 768px) {
  .AppHeaderComponent_main {
    align-items: center;
    padding: 0px 5px;
  }
  .AppHeaderComponent_main_navbar_burger {
    display: flex;
    padding: 0px 0px 0px 10px;
  }
}

@media(max-width: 414px) {
  .AppHeaderComponent_main {
    align-items: center;
    padding: 0px 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-height: 100% !important;
    min-height: auto !important;
  }
  .AppHeaderComponent_main_first {
    padding: 10px 0px;
  }
  .AppHeaderComponent_main_first_trial_title {
    height: 100%;
  }
  .AppHeaderComponent_main_first_trial {
    min-width: 100%;
    height: 100%;
    padding: 10px 20px;
    margin: 0;
  }
  .AppHeaderComponent_main_navbar_burger {
    display: flex;
    padding: 0px 0px 0px 10px;
  }
}
