.signup {
  padding: 14px 36px;
  height: 46px;
}

.nameContainer {
  display: flex;
}

.policy {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
}

.CreateAccount_policy_P {
  color: #000;
  font-size: 12px;
  font-weight: 200;
  text-align: center;
  margin: 0;
  padding: 0;
  font-family: var(--main-font);
  padding: 5px 0;
}

.CreateAccount_policy_P a {
  color: #000;
  font-size: 12px;
  font-weight: 200;
  text-align: center;
  margin: 0;
  padding: 0;
  font-family: var(--main-font);
  padding: 5px 0;
}
