


.lockedButton:hover{
  background: rgba(0, 108, 255, 0.1);
  border: none;
}

.lockedButton{
  background: rgba(0, 108, 255, 0.1);
  border: none;
  margin: 0px 0px 0px 26px;
}

.lockedButton span{
  color: #006CFF;
}

.accountButton span{
  color: #006CFF;
}