.BotComponent_dashboard_ {
  display: flex;
  background-color: #ffffff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: all 0.3s;
  position: absolute;
  height: 100%;
  z-index: 8;
}

.BotComponent_content_container {
  overflow: auto;
  height: 100%;
  width: 100%;
}

.BotComponent_dashboard_header_cross {
  display: none;
}
.BotComponent_main {
  height: 100%;
  width: 100%;
  display: flex;
}

.BotComponent_main::-webkit-scrollbar {
  display: none;
}

.BotComponent_content_container::-webkit-scrollbar {
  display: none;
}

.BotComponent_dashboard_wrapper {
  flex: 1;
  height: 100%;
  transition: opacity 0.3s;
  overflow: hidden;
}

.BotComponent_dashboard_back_button {
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 35px;
}

.BotComponent_dashboard_back_button span {
  font-size: 12px;
  font-family: var(--main-font);
  color: #9e9e9e;
}

.BotComponent_dashboard_back_button img {
  height: 12px;
  margin-right: 22px;
}

.BotComponent_dashboard_content {
  padding-top: 75px;
}

.BotComponentDashButtonComponent {
  text-decoration: none;
}

.BotComponentDashButtonComponent .BotComponentDashButtonComponent_mainrout {
  width: 100%;
  display: flex;
  text-decoration: none;
  border-left: 2px solid white;
  /* height: 40px; */
}

.BotComponentDashButtonComponent svg path {
  fill: #9e9e9e;
  transition: all 0.3s;
}

.BotComponentDashButtonComponent_logo_wrapper {
  min-width: 20px;
  max-width: 20px;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.BotComponentDashButtonComponent svg {
  min-width: 100%;
  transition: all 0.3s;
}

.BotComponentDashButtonComponent span {
  font-size: 13px;
  font-family: var(--main-font);
  color: #9e9e9e;
  transition: all 0.3s;
  font-weight: 500;
  white-space: nowrap;
  padding: 20px 0;
}

.BotComponentDashButtonComponentActive
  .BotComponentDashButtonComponent_mainrout {
  border-left: 2px solid #006cff;
  background-color: #fafafa;
}

.BotComponentDashButtonComponentActive span {
  color: #212121;
}

.BotComponentDashButtonComponentActive svg path {
  fill: #212121;
}

.BotComponentDashButtonComponent svg line {
  stroke: #9e9e9e;
  transition: all 0.3s;
}

.BotComponentDashButtonComponentActive svg line {
  stroke: #212121;
}

.BotComponentDashButtonComponent svg rect {
  stroke: #9e9e9e;
  transition: all 0.3s;
}

.BotComponentDashButtonComponentActive svg rect {
  stroke: #212121;
}

.BotComponentDashButtonComponent svg circle {
  stroke: #9e9e9e;
  transition: all 0.3s;
}

.BotComponentDashButtonComponentActive svg circle {
  stroke: #212121;
}

.BotComponentDashButtonComponent_subrout {
  display: none;
  flex-direction: column;
}

.active_nav_tab .BotComponentDashButtonComponent_subrout {
  display: flex;
}

.nav_tab:hover .BotComponentDashButtonComponent_mainrout span {
  color: #212121;
  text-decoration: underline;
}

.nav_tab:hover .BotComponentDashButtonComponent_logo_wrapper svg path {
  fill: #212121;
}

.BotComponentDashButtonComponent_subrout_link:hover {
  color: #212121;
  text-decoration: underline;
}

.BotComponent_dashboard_toggle_wrapper {
  width: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  background: white;
  cursor: pointer;
  user-select: none;
}

.BotComponent_dashboard_toggle_wrapper:hover {
  background: rgba(0, 0, 0, 0.01);
}

.BotComponent_dashboard_toggle_wrapper img {
  transition: all 0.3s;
}

.BotComponent_content {
  position: relative;
  overflow: hidden;
  flex: 1;
  width: 1px;
  display: flex;
  flex-direction: column;
  padding-left: 80px;
}

.BotComponent_dashboard_header_bot {
  display: flex;
  cursor: pointer;
}

.BotComponent_dashboard_header_bot_avatar_wrapper {
  position: relative;
  /* Style for "Эллипс 1 к" */
  width: 40px;
  height: 40px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  min-width: 40px;
  transition: all 0.3s;
}
.BotComponent_dashboard_header_bot_image_wrapper {
  /* Style for "Эллипс 1 к" */
  width: 40px;
  height: 40px;
  background-color: #f2f2f2;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  min-width: 40px;
  transition: all 0.3s;
}

.BotComponent_dashboard_header_bot_avatar_wrapper img {
  height: 100%;
}
.BotComponent_dashboard_header_bot_avatar_notification {
  position: absolute;
  width: 12px;
  height: 12px;
  background: greenyellow;
  border: 1px solid white;
  border-radius: 100%;
  overflow: auto;
  bottom: 0;
  right: 0;
}

.BotComponent_dashboard_header_bot_name {
  flex: 1;
  display: flex;
  align-items: center;
}

.BotComponent_dashboard_header_bot {
  padding-top: 20px;
}

.BotComponent_dashboard_header_arrow {
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.BotComponent_dashboard_header_bot_name span {
  white-space: nowrap;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100px;
  font-family: var(--main-font);
  font-size: 13px;
  font-weight: 500;
}

.BotComponentDashButtonComponent_mainrout {
  flex: 1;
  display: flex;
  align-items: center;
}

.BotComponentDashButtonComponent_subrout_link {
  padding-left: 70px;
  height: 40px;
  display: flex;
  align-items: center;
  color: #212121;
  white-space: nowrap;
  font-size: 13px;
  font-family: var(--main-font);
  text-decoration: none;
  border-left: 2px solid white;
}

.BotComponentDashButtonComponent_subrout_link_active {
  border-left: 2px solid #006cff;
  background-color: #fafafa;
}

.BotComponentDashButtonComponentActive_sub
  .BotComponentDashButtonComponent_mainrout {
  border-left: 2px solid white;
  background-color: white;
}

.BotComponentDashButtonComponentActive_sub span {
  color: #212121;
}

.BotComponentDashButtonComponentActive_sub svg path {
  fill: #212121;
}

.BotComponentDashButtonComponentActive_sub svg line {
  stroke: #212121;
}

.BotComponentDashButtonComponentActive_sub svg rect {
  stroke: #212121;
}

.BotComponentDashButtonComponentActive_sub svg circle {
  stroke: #212121;
}

.BotComponentDashButtonComponent_sub_t
  .BotComponentDashButtonComponent_mainrout {
  border-left: 2px solid white;
  background-color: white;
}

.BotComponentDashButtonComponent_sub_t span {
  color: #212121;
}

.BotComponentDashButtonComponent_sub_t svg path {
  fill: #212121;
}

.BotComponentDashButtonComponent_sub_t svg line {
  stroke: #212121;
}

.BotComponentDashButtonComponent_sub_t svg rect {
  stroke: #212121;
}

.BotComponentDashButtonComponent_sub_t svg circle {
  stroke: #212121;
}

.BotComponent_bot_popup {
  position: fixed;
  width: 280px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  background: white;
  border-radius: 10px;
  display: none;
  flex-direction: column;
  top: 10px;
  left: 90px;
  z-index: 12;
}

.BotComponent_bot_popup_td_name {
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.BotComponent_bot_popup_bot_name {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.BotComponent_bot_popup_header_bot_avatar_wrapper {
  width: 40px;
  height: 40px;
  background-color: #f2f2f2;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  transition: all 0.3s;
  margin-right: 10px;
}

.BotComponent_bot_popup_header_bot_avatar_wrapper img {
  height: 100%;
}

.BotComponent_bot_popup_header_bot_name {
  flex: 1 1;
  display: flex;
  align-items: center;
}
.BotComponent_bot_popup_header_bot_name span {
  white-space: nowrap;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 190px;
  font-family: var(--main-font);
  font-size: 13px;
  font-weight: 500;
  flex: 1;
}

.BotComponent_bot_popup_header_arrow {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.BotComponent_bot_popup_header_arrow img {
  transform: rotate(180deg);
}

.BotComponent_bot_popup_td_midle {
  border-top: 1px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
  max-height: 300px;
}

.BotComponent_bot_popup_td {
  display: flex;
  justify-content: center;
  padding: 20px 0;
}

.BotComponent_bot_popup_Create_new_bot {
  width: 240px;
  height: 40px;
  border-radius: 4px;
  background-color: #006cff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.BotComponent_bot_popup_Create_new_bot span {
  font-family: var(--main-font);
  color: white;
  font-size: 12px;
  font-weight: 500;
}

.BotComponent_bot_popup_search_input_wrapper {
  display: flex;
  margin-top: 15px;
  margin-bottom: 5px;
  cursor: pointer;
}

.BotComponent_bot_popup_search_icon {
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
}
.BotComponent_bot_popup_search_input_wrapper:focus-within
  .BotComponent_bot_popup_search_icon {
  width: 20px;
}

.BotComponent_bot_popup_search_icon img {
  width: 50%;
}

.BotComponent_bot_popup_search_input {
  display: flex;
  align-items: center;
  flex: 1;
}

.BotComponent_bot_popup_search_input input {
  height: 25px;
  width: 100%;
  border: none;
  outline: none;
  font-family: var(--main-font);
  font-weight: 200;
}

.PopupFolderComponent_main {
  cursor: pointer;
}

.PopupFolderComponent_folder_name_wrapper {
  display: flex;
  align-items: center;
  margin: 15px 0;
}

.BPopupFolderComponent_folder_arrow {
  margin-right: 10px;
  display: flex;
  align-items: center;
  width: 17px;
}

.PopupFolderComponent_folder_name span {
  white-space: nowrap;
  display: block;
  font-family: var(--main-font);
  font-size: 13px;
  font-weight: 500;
  color: #9e9e9e;
  transition: all 0.3s;
}

.GeneralTogle_content {
  overflow: hidden;
  transition: all 0.3s ease 0s;
}

.PopupFolderComponent_bot_popup_bot_name {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.PopupFolderComponent_bot_popup_header_bot_avatar_wrapper {
  width: 40px;
  height: 40px;
  background-color: #f2f2f2;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  transition: all 0.3s;
  margin-right: 10px;
}

.PopupFolderComponent_bot_popup_header_bot_avatar_wrapper img {
  height: 100%;
}

.PopupFolderComponent_bot_popup_header_bot_name {
  flex: 1 1;
  display: flex;
  align-items: center;
}

.PopupFolderComponent_bot_popup_header_bot_name span {
  white-space: nowrap;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 190px;
  font-family: var(--main-font);
  font-size: 13px;
  font-weight: 500;
  flex: 1 1;
}

.PopupFolderComponent_folder_name_wrapperr_open
  .PopupFolderComponent_folder_name
  span {
  color: #212121;
}

.BPopupFolderComponent_folder_arrow svg {
  transition: all 0.3s;
}

.PopupFolderComponent_folder_name_wrapperr_open
  .BPopupFolderComponent_folder_arrow
  svg {
  transform: rotate(-180deg);
}

.customScroll::-webkit-scrollbar {
  width: 7px;
}

.customScroll::-webkit-scrollbar-thumb {
  border-radius: 7px;
  border-width: 1px 1px 1px 2px;
  border-color: #777;
  background-color: #aaa;
}

.customScroll::-webkit-scrollbar-thumb:hover {
  border-width: 1px 1px 1px 2px;
  border-color: #555;
  background-color: #777;
}

.customScroll::-webkit-scrollbar-track {
  border-width: 0;
}

.customScroll::-webkit-scrollbar-track:hover {
  background-color: #eee;
}

.rootView_container_one {
  font-family: var(--main-font);
  font-size: 18px;
  color: #000000;
  font-weight: 500;
}

.rootView_container_main {
  font-family: var(--main-font);
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
  font-weight: 500;
}

.PopupFolderComponent_bot_popup_bot_name_link {
  margin: 10px 0;
  text-decoration: none;
  color: #000000;
}

.GeneralTogle_content_height_popup {
  padding: 0 20px;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: 200px;
}

.GeneralTogle_content_height {
  height: 100%;
}

.PopupFolderComponent_bot_popup_bot_name_link:hover {
  text-decoration: underline;
}

@media (min-width: 414px) and (max-width: 768px) {
  .BotComponent_content {
    padding: 10px 5px;
    overflow: auto;
  }
  .BotComponent_dashboard_ {
    /* width: auto !important; */
    position: absolute;
    width: 250px !important;
    /* margin: 0px 0px 0px 80px; */
  }
  .BotComponent_dashboard_wrapper {
    overflow-y: scroll;
  }
  .BotComponent_bot_popup {
    width: 70%;
    padding: 0px 5px;
  }
  .BotComponent_dashboard_header_cross {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 30px 0px 0px;
  }
  .BotComponent_dashboard_header_arrow {
    padding: 0px 20px 0px 0px !important;
  }
  .BotComponent_dashboard_header {
    display: flex;
    align-items: center;
  }
  .BotComponent_bot_popup_header_bot_name span {
    width: 170px;
  }
}

@media (max-width: 414px) {
  .BotComponent_content {
    padding: 10px 5px;
    overflow: auto;
  }
  .BotComponent_dashboard_ {
    /* width: auto !important; */
    position: absolute;
    width: 250px !important;
    margin: 0px 0px 0px 80px;
  }
  .BotComponent_dashboard_wrapper {
    overflow-y: scroll;
  }
  .BotComponent_bot_popup {
    width: 70%;
    padding: 0px 5px;
  }
  .BotComponent_dashboard_header_cross {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 30px 0px 0px;
  }
  .BotComponent_dashboard_header_arrow {
    padding: 0px 20px 0px 0px !important;
  }
  .BotComponent_dashboard_header {
    display: flex;
    align-items: center;
  }
  .BotComponent_bot_popup_header_bot_name span {
    width: fit-content;
    margin: 0px 5px 0px 0px;
  }
  /* .GeneralTogle_content {
    overflow: auto !important;
  } */
}
