.select_main {
  position: relative;
  cursor: pointer;
}
.titled_select_title {
  position: absolute;
  background: white;
  width: fit-content;
  height: fit-content;
  top: -2px;
  left: 10px;
  padding: 0 1px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 12px;

  transform: translateY(-50%);
}
.select_main_name {
  display: flex;
  align-items: center;
  cursor: pointer;
  flex: 1;
  padding: 0 15px;
  justify-content: flex-end;
  width: 100%;
}

.select_main_content {
  position: absolute;
  overflow: hidden;
  transition: all 0.3s;
}

.select_arrow_down {
  display: flex;
  align-content: center;
  height: 6px;
  transition: all 0.3s;
  margin-left: 15px;
}

.select_arrow_down_rotete {
  transform: rotate(180deg);
}

.select_arrow_down svg {
  height: 100%;
}

.select_main_name span {
  margin: 0;
}

.select_main_name span {
  font-size: 14px;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  font-family: var(--main-font);
}

.select_main_content {
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  color: #212121;
  width: calc(100% + 2px);
  background: white;
}

.select_acuikit {
  display: flex;
  flex: 1;
  position: relative;
  min-width: 90px;
  /* padding: 0 15px; */
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: 40px;
  border-radius: 4px;
  background: white;
  width: 100%;
}

.select_acuikit .select_main_name span {
  font-size: 12px;
  color: #006cff;
  font-weight: 500;
  font-family: var(--main-font);
  flex: 1;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  text-overflow: ellipsis;
}

.select_acuikit .select_main_content {
  top: 39px;
  left: -1px;
  background: white;
  border-radius: 0 0 4px 4px;
  z-index: 7;
}

.select_main_conteiner {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0 0 4px 4px;
  width: 100%;
}

.select_main_conteinerisTop {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px 4px 0 0;
  width: 100%;
}

.select_acuikit .select_main_option {
  height: 40px;
  display: flex;
  align-items: center;
  margin: 4px 0;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.38);
  padding: 10px;
}

.select_acuikit .select_main_option:hover {
  background: rgba(0, 0, 0, 0.02);
  color: rgba(0, 0, 0, 0.87);
}

.select_acuikit_active {
  /* border: 1px solid white; */
  /* box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1); */
  border-radius: 4px 4px 0 0;
}

.select_acuikit_activeisTop {
  /* border: 1px solid white; */
  /* box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1); */
  border-radius: 0 0 4px 4px;
}

.select_acuikit_active .select_main_name {
  /* height: 106%; */
}

.select_main_option .set_columns {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 0 10px;
}

.selectScroll {
  overflow: auto;
  max-height: 300px;
  overflow-y: overlay;
}

.selectScroll::-webkit-scrollbar {
  width: 2px;
}

.selectScroll::-webkit-scrollbar-thumb {
  border-radius: 2px;
  border-width: 1px 1px 1px 1px;
  border-color: #777;
  background-color: #aaa;
}

.selectScroll::-webkit-scrollbar-thumb:hover {
  border-width: 1px 1px 1px 1px;
  border-color: #555;
  background-color: #777;
}

.selectScroll::-webkit-scrollbar-track {
  border-width: 0;
}

.selectScroll::-webkit-scrollbar-track:hover {
  background-color: #eee;
}

.select_main_option_active {
  background: rgba(0, 0, 0, 0.02);
  color: rgba(0, 0, 0, 0.87);
}
.select_main_disabled {
  display: none;
}

@media (max-width: 414px) {
  .select_main_name {
    padding: 0px 5px !important;
  }
  .select_main_name span {
    font-size: 10px !important;
  }
}
.select_ac_locked {
  background: rgba(0, 0, 0, 0.1) !important;
  pointer-events: none;
  cursor: not-allowed !important;
}
