/**
 * ==============================================
 * Dot Pulse
 * ==============================================
 */
.dot-pulse {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #006cff;
  color: #006cff;
  box-shadow: 9999px 0 0 -5px #006cff;
  animation: dotPulse 1.5s infinite linear;
  animation-delay: 0.25s;
}

.dot-pulse::before,
.dot-pulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #006cff;
  color: #006cff;
}

.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px #006cff;
  animation: dotPulseBefore 1.5s infinite linear;
  animation-delay: 0s;
}

.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px #006cff;
  animation: dotPulseAfter 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dotPulseBefore {
  0% {
    box-shadow: 9984px 0 0 -5px #006cff;
  }
  30% {
    box-shadow: 9984px 0 0 2px #006cff;
  }
  60%,
  100% {
    box-shadow: 9984px 0 0 -5px #006cff;
  }
}

@keyframes dotPulse {
  0% {
    box-shadow: 9999px 0 0 -5px #006cff;
  }
  30% {
    box-shadow: 9999px 0 0 2px #006cff;
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -5px #006cff;
  }
}

@keyframes dotPulseAfter {
  0% {
    box-shadow: 10014px 0 0 -5px #006cff;
  }
  30% {
    box-shadow: 10014px 0 0 2px #006cff;
  }
  60%,
  100% {
    box-shadow: 10014px 0 0 -5px #006cff;
  }
}
.waiting_snippet {
  margin: 30px;
}
.waiting_stage {
  display: flex;
  justify-content: center;
}
.waiting_conponent {
  font-family: var(--main-font);
}
.waiting_text span {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #212121;
}
.waiting_description,
.waiting_text {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.waiting_description span {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #757575;
}
