.dropDown_acuikit {
    z-index: 7;
    height: 40px;
    display: flex;
    align-items: center;
    background: #006cff;
    border-radius: 4px;
}

.dropdown_header {
    display: flex;
    align-items: center;
}

.arrow_wrapper {
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    /* background: #0060e5; */
    border-radius: 0 4px 4px 0;
}

.dropDown_arrow_down {
    display: flex;
    align-content: center;
    height: 8px;
    transition: all 0.3s;
}

.dropDown_arrow_down svg {
    height: 100%;
}

.dropDown_acuikit .select_main_name {
    min-width: 100px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding-left: 35px; */
    height: 40px;
}

.dropDown_acuikit .select_main_name:hover {
    /* background: #0060e5; */
    border-radius: 4px 4px 4px 4px;
}

.dropDown_acuikit .select_main_name span {
    color: #ffffff;
}

.dropDown_acuikit .select_main_content {
    top: 39px;
    left: 0px;
    background: white;
    border-radius: 0 0 4px 4px;
    z-index: 7;
}

.dropDown_acuikit .select_main_option {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 4px 0;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.38);
}

.dropDown_acuikit .select_main_option:hover {
    background: rgba(0, 0, 0, 0.02);
}

.dropDown_acuikit .select_main_content {
    width: 100%;
}
