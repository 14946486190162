.reset_password_page {
  display: flex;
  width: 100%;
  padding: 10px;
  margin: 100px auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media only screen and (min-width: 401px) {
  .reset_password_page {
    display: flex;
    margin: 100px auto;
    max-width: 412px;
    min-width: 300px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.reset_password_page_title {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 30px;
  margin-top: -50px;
}
.reset_password_page_title span {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 38px;
  line-height: 52px;
  text-align: center;
  color: #2b2b2b;
}
