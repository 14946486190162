.table_checkbox_counter {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--main-font);
    font-size: 12px;
    font-weight: 500;
}

.TableComponent_main {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
}

.HeaderTableStyle {
    width: 100%;
    flex: 1;
    display: flex;
    min-height: 60px;
}

.TdTable {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--main-font);
    font-size: 12px;
    font-weight: 500;
}

.TdTable-df {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-family: var(--main-font);
    font-size: 12px;
    font-weight: 500;
}

.ContentTableStyle_blur {
    filter: blur(2px);
    height: 100%;
}

.ContentTableTd {
    font-family: var(--main-font);
    font-size: 12px;
    font-weight: 200;
    text-align: center;
}

.ContentTableStyle {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    overflow: auto;
    border-top: 1px solid #f2f2f2;
    position: relative;
}
.ContentTableTr {
    display: flex;
    min-height: 60px;
    border-bottom: 1px solid #f2f2f2;
}
.ContentTableTd {
    flex: 1;
    display: flex;
    align-items: center;
    text-align: center;
}

.ContentTableTd-df {
    flex: 1;
    display: flex;
    align-items: center;
}

.table_checkbox_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.style_table_scroll {
    overflow-y: overlay;
}

.style_table_scroll::-webkit-scrollbar {
    width: 7px;
}

.style_table_scroll::-webkit-scrollbar-thumb {
    border-radius: 7px;
    border-width: 1px 1px 1px 2px;
    border-color: #777;
    background-color: #aaa;
}

.style_table_scroll::-webkit-scrollbar-thumb:hover {
    border-width: 1px 1px 1px 2px;
    border-color: #555;
    background-color: #777;
}

.style_table_scroll::-webkit-scrollbar-track {
    border-width: 0;
}

.style_table_scroll::-webkit-scrollbar-track:hover {
    background-color: #eee;
}

.ContentTableTr:last-child {
    border-bottom: none;
}

.arrow_sort {
    margin-left: 10px;
    transition: all 0.3s;
}

.tableLoading {
    position: absolute;
    height: 100%;
    width: 100%;
    padding: 40px 0;
    /* background: rgba(255, 255, 255, 0.8); */
    filter: blur(0px);
}

.tableNoData {
    position: absolute;
    height: 100%;
    width: 100%;
    padding: 40px 0;
    /* background: rgba(255, 255, 255, 0.8); */
    filter: blur(0px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.tablecheckbox {
    margin-right: 25px;
    margin-left: 20px;
}

@keyframes load {
    0% {
        opacity: 0.08;
        /*         font-size: 10px; */
        /* 				font-weight: 400; */
        filter: blur(5px);
        letter-spacing: 3px;
    }
    100% {
        /*         opacity: 1; */
        /*         font-size: 12px; */
        /* 				font-weight:600; */
        /* 				filter: blur(0); */
    }
}

.animate {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: auto;
    /* 	width: 350px; */
    /* 	font-size:26px; */
    font-family: var(--main-font);
    font-size: 12px;
    font-weight: 200;
    animation: load 1.2s infinite 0s ease-in-out;
    animation-direction: alternate;
    text-shadow: 0 0 1px white;
    background: rgba(0, 0, 0, 0.1);
}
.tableFetching {
    position: absolute;
    bottom: 0;
    width: 100%;
}

@media (max-width: 414px) {
    .table_checkbox_wrapper {
        width: 20px !important;
        margin: 0px 5px 0px 0px !important;
    }
}
