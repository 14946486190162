.bots_stats_container {
  display: none;
}

.group_bot_class {
  margin-top: 20px;
}

.bots_group_wrapper {
  display: flex;
  flex-direction: column;
}

.group_bot_select .select_main_name span {
  color: #006cff;
}

.group_bot_select_active .select_main_name span {
  color: #212121;
}

.group_bot_select .select_main_name {
  /* Style for "Прямоуголь" */
  width: 110px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #f2f2f2;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.group_bot_select_active {
  background-color: #ffffff;
}

.group_bot_select .select_main_content .group_bot_select_option {
  /* Style for "Прямоуголь" */
  width: 110px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #212121;
  font-size: 12px;
  font-family: var(--main-font);
  font-weight: 500;
  cursor: pointer;
}

.group_bot_select .select_main_option {
  border-left: 1px solid #f2f2f2;
  border-right: 1px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
}

.group_bot_select .select_main_option:last-child {
  border-radius: 0px 0px 4px 4px;
}

.group_bot_select_active .select_main_name {
  border-radius: 4px 4px 0px 0px;
}

.group_bot_select .group_bot_select_option:hover {
  background-color: rgba(242, 242, 242, 0.5);
}

.group_bot_class {
  padding: 0 !important;
}

.group_bot_class .GeneralTogle_header {
  margin: 20px;
  /* margin-bottom: 0px; */
}

.bot_table_name {
  font-family: var(--main-font);
  font-size: 12px;
  font-weight: 500;
  color: #006cff;
}

.bot_table_field {
  font-family: var(--main-font);
  font-size: 12px;
  font-weight: 300;
  text-align: center !important;
}

.bot_name_flex_3 {
  flex: 3 !important;
  justify-content: flex-start;
  margin-left: 20px;
}

.bot_name_flex_2 {
  display: flex;
  flex: 2 !important;
  justify-content: center;
}

.table_actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.table_actions_duplicate {
  /* Style for "Прямоуголь" */
  width: 30px;
  height: 30px;
  border-radius: 4px;
  border: 1px solid #f2f2f2;
  background-color: #ffffff;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  cursor: pointer;
}

.table_actions_delete {
  /* Style for "Прямоуголь" */
  width: 30px;
  height: 30px;
  border-radius: 4px;
  background-color: #ff6f61;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  cursor: pointer;
}

.table_actions_menu {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.bot_table_link_style {
  color: #006cff;
  text-decoration: none;
}

.GeneralTogle_header_arrow {
  display: flex;
  align-items: center;
}

.bots_main_container {
  overflow: auto;
}

.bots_main_container::-webkit-scrollbar {
  display: none;
}

.bot_table_title_wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
}
.bot_table_pro_plan {
  display: flex;
  padding: 3px;
  border-radius: 4px;
  background: var(--main-color);
  font-family: var(--main-font);
  font-weight: bold;
  color: white;
  margin-right: 10px;
}

.new_user_content_intro {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-family: var(--main-font);
  font-size: 22px;
  font-weight: 600;
}

.new_user_content_header {
  padding: 0px 0px 30px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-family: var(--main-font);
  font-size: 22px;
  font-weight: 600;
}

.new_user_content {
  padding: 30px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-family: var(--main-font);
  font-size: 22px;
  font-weight: 600;
}

.new_user_content_description {
  height: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  width: 30%;
  font-family: var(--main-font);
  font-size: 14px;
  font-weight: 600;
  text-align: start;
}

.new_user_content_video {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 0px 20px;
}

.new_user_content_description_button div {
  width: 250px;
}

.new_user_content_description_button {
  align-self: center;
}

.new_user_content_description_list {
  padding: 0px 0px 0px 30px;
}

.bots_loading {
  position: absolute;
  top: 50%;
  left: 50%;
}

@media(max-width: 414px) {
  .bots_main_container {
    padding: 0px 5px !important;
  }
  .new_user_content_video {
    flex-direction: column;
    padding: 0;
  }
  .new_user_content_description {
    width: 100%;
    margin: 10px 0px;
  }
  .bot_table_name {
    overflow: auto;
  }
}