.InformComponent_main_container {
  position: relative;
  z-index: 6;
}

.InformComponent_main_message_container {
  position: absolute;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  border-radius: 10px;
  left: 50%;
  transform: translateX(-50%);
  top: 35px;
}

.InformComponent_main_container img {
  cursor: pointer;
  position: relative;
  z-index: 2;
}

.InformComponent_main_message_content {
  position: relative;
  padding: 20px;
}

.InformComponent_main_message_sqwer_wrapper {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -10px;
}

.InformComponent_main_message_sqwer {
  height: 20px;
  width: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  transform: rotate(45deg);
  background-color: #ffffff;
}

.InformComponent_main_message_hide {
  width: 50px;
  height: 20px;
  position: absolute;
  background-color: #ffffff;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
}

.InformComponent_main_message_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.InformComponent_main_message_header_td {
  flex: 1;
}

.InformComponent_main_message_header_end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  right: 10px;
  top: 10px;
}

.InformComponent_main_message_contant_wrapper {
  min-width: 240px;
}

.InformComponent_main_message_header_title {
  font-family: var(--main-font);
  font-size: 12px;
  font-weight: 500;
  color: #212121;
}

.InformComponent_main_message_closebutton {
  display: flex;
  align-items: center;
}

.InformComponent_main_message_closebutton {
  cursor: pointer;
}

.InformComponent_main_message_contant_wrapper {
  font-family: var(--main-font);
  font-size: 12px;
  font-weight: 100;
}

.InformComponent_main_svg {
  height: 10px;
  width: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.InformComponent_main_svg svg {
  height: 100%;
}
.GeneralTogle_header_content_info {
  margin-left: 10px;
}