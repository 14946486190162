.NotfoundComponent_main {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100%;
}

.NotfoundComponent_container {
  display: flex;
}

.NotfoundComponent_text_Container {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  padding-top: 10vh;
}

.NotfoundComponent_anim {
  height: 500px;
  width: 500px;
}

.NotfoundComponent_text_Container h1 {
  font-family: var(--main-font);
  font-size: 45px;
  font-weight: 200;
  margin-bottom: 10px;
}
.NotfoundComponent_text_Container p {
  font-family: var(--main-font);
  font-size: 15px;
  font-weight: 200;
}
