@keyframes logo_anim {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.loading_logo {
  width: 12%;
  height: 12%;
  animation: logo_anim 0.8s infinite alternate;
}
.LoadingComponent_wrapper {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex: 1;
}
/* .LoadingComponent_wrapper img {
  height: 7vh;
} */
