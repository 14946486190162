.new_bot_component {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 120px;
  font-family: var(--main-font);
}
.new_bot_header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}
.new_bot_header_title {
  margin-bottom: 20px;
  font-size: 24px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #212121;
}
.new_bot_header_steps {
  display: flex;
}
.new_bot_header_step_item {
  width: 48px;
  height: 2px;
  margin: 0 2px;
  border-radius: 13px;
}
.new_bot_body_step_0 {
  display: flex;
}
.new_bot_header_desc {
  margin-top: 24px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #757575;
  white-space: pre-line;
}
.new_bot_footer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
}
.new_bot_footer_button {
  margin: 0 8px;
}
.new_bot_body_step_1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 280px;
}
.new_bot_body_card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
  height: 200px;
  margin: 0 20px;
  border: 1px solid #ececec;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
}
.new_bot_body_card:hover,
.new_bot_body_card_selected {
  border: 1px solid #0060e5;
}
.new_bot_body_card_desc {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #212121;
}
.new_bot_body_card_icon {
  margin-top: 45px;
  margin-bottom: 30px;
}
.new_bot_body_image_upload {
  margin-bottom: 28px;
}
.new_bot_body_image_upload_area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 280px;
  height: 88px;
  border: 1px dashed #ececec;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
}
.new_bot_body_image_upload_area:hover {
  border: 1px dashed #0060e5;
}
.new_bot_body_image_upload_area_text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #b0b0b0;
}
.new_bot_body_image_upload_area_icon {
  margin-bottom: 8px;
}
.new_bot_body_image {
  width: 88px;
  height: 88px;
  margin-right: 8px;
  box-sizing: border-box;
  border-radius: 4px;
  overflow: hidden;
}
.new_bot_body_image_editor {
  display: flex;
  justify-content: center;
  align-items: center;
}
.new_bot_body_image_controls_item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  background: #ececec;
  border-radius: 4px;
  cursor: pointer;
}
.new_bot_body_image_controls_item svg {
  width: 10px;
}
.new_bot_body_image_controls {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 88px;
}
.new_bot_body_input {
  margin-bottom: 20px;
}
.build_your_first_bot_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.build_your_first_bot_button_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 125px;
  height: 38px;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 35px;
  cursor: pointer;
  background: #006cff;
  text-decoration: none;
}

.build_your_first_bot_button_wrapper span {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height, or 129% */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.00615385em;

  /* White */

  color: #ffffff;
}
.build_your_first_bot_lang {
  width: 300px;
}
.build_your_first_bot_button {
  margin-top: 20px;
}
.build_your_first_bot_lang {
  margin-top: 40px;
}
.build_your_first_bot_step_text {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #212121;
}
.build_your_first_bot_goTo {
  display: flex;
  margin-top: 40px !important;
}
.build_your_first_bot_goTo_item {
  width: 280px;
  margin: 0 40px;
}
.build_your_first_bot_goTo_item_header {
  display: flex;
  align-items: center;
  height: 80px;
  margin-bottom: 15px;
}

.build_your_first_bot_goTo_item_desc {
  font-size: 13px;
  line-height: 16px;
  color: #212121;
  margin-bottom: 24px;
}
.new_bot_lang_desc {
  margin: 5px 0px 20px 0px;
  max-width: 283px;
}
.new_bot_lang_desc span {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  font-feature-settings: "tnum" on, "lnum" on, "ss01" on, "salt" on, "ordn" on,
    "kern" off;
  color: #b0b0b0;
}
.new_bot_body_inputs {
  width: 100%;
}
.new_bot_body_input_lang {
  margin-bottom: 5px !important;
}
