.ChatLandingComponent_main{
    height: 100%;
    background-image: linear-gradient(to right, #006cff 0%, #00bfff 100%);
    
    display: flex;
    flex-direction: column;
}
.ChatLandingComponent_header{
    padding: 0 40px;
    padding-top: 40px;
}

.ChatLandingComponent_content{
    flex: 1;
    display: flex;
    flex-direction: column;
}

.ChatLandingComponent_header_image_wrapper{
    max-width: 288px;

}

.ChatLandingComponent_header_image{
    width: 100%;
}

.ChatFooterComponent_main_wrapper__qr_button{
    /* padding-bottom: 18px; */
    display: -ms-flexbox;
    max-width: 822px;
    margin: 0 auto;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 18px;
}

.chatLandinWrapper{
    height: 100%;
    overflow: hidden;

}

.ACCW-FRAME{
    height: 100%;
    width: 100%;
    user-select: none;
}