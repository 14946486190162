.build_your_first_bot_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.build_your_first_bot_button_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 125px;
  height: 38px;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 35px;
  cursor: pointer;
  background: #006cff;
  text-decoration: none;
}

.build_your_first_bot_goTo_item_button {
  display: flex;
  justify-content: center;
}

.build_your_first_bot_button_wrapper span {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height, or 129% */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.00615385em;

  /* White */

  color: #ffffff;
}
.build_your_first_bot_lang {
  width: 300px;
}
.build_your_first_bot_button {
  margin-top: 20px;
}
.build_your_first_bot_lang {
  margin-top: 40px;
}
.build_your_first_bot_step_text {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #212121;
}
.build_your_first_bot_goTo {
  display: flex;
  margin-top: 80px;
}
.build_your_first_bot_goTo_item {
  width: 280px;
  margin: 0 40px;
}
.build_your_first_bot_goTo_item_header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  margin-bottom: 15px;
}

.build_your_first_bot_goTo_item_desc {
  font-size: 13px;
  line-height: 16px;
  color: #212121;
  margin-bottom: 24px;
}
.new_bot_lang_desc {
  margin: 5px 0px 20px 0px;
  max-width: 283px;
}
.new_bot_lang_desc span {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  font-feature-settings: "tnum" on, "lnum" on, "ss01" on, "salt" on, "ordn" on,
    "kern" off;
  color: #b0b0b0;
}
