.PopupComponent_main {
  position: fixed;
  height: 100%;
  right: 0;
  top: 0;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  width: 380px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: all 0.3s;
  z-index: 7;
}

.PopupComponent_main_header {
  margin-bottom: 20px;
  background-image: linear-gradient(to right, #006cff 0%, #00bfff 100%);
  height: 60px;
  display: flex;
}

.PopupComponent_main_header_logo {
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 10px;
}

.PopupComponent_main_header_name {
  flex: 1;
  display: flex;
  align-items: center;
}

.PopupComponent_main_header_name span {
  color: #ffffff;
  font-family: var(--main-font);
  font-size: 14px;
  font-weight: 600;
}

.PopupComponent_main_header_close {
  padding: 0 22px;
  display: flex;
  align-items: center;
}

.PopupComponent_main_header_close_button {
  cursor: pointer;
}

.PopupComponent_main_content {
  flex: 1;
  overflow: auto;
  overflow-y: overlay;
  overflow-x: hidden;
}

/* .PopupComponent_main_content div {
  overflow: scroll;
} */

.customScroll::-webkit-scrollbar {
  width: 7px;
}

.customScroll::-webkit-scrollbar-thumb {
  border-radius: 7px;
  border-width: 1px 1px 1px 2px;
  border-color: #777;
  background-color: #aaa;
}

.customScroll::-webkit-scrollbar-thumb:hover {
  border-width: 1px 1px 1px 2px;
  border-color: #555;
  background-color: #777;
}

.customScroll::-webkit-scrollbar-track {
  border-width: 0;
}

.customScroll::-webkit-scrollbar-track:hover {
  background-color: #eee;
}

@media (max-width: 414px) {
  .PopupComponent_main {
    width: 100% !important;
  }
}
