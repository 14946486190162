.wizzard_component_wrapper {
  margin-top: 40px;
  padding: 20px 10px !important;
}
.wizzard_header_wrapper {
  display: flex;
  font-family: var(--main-font);
  overflow: hidden;
  margin-top: 10px;
  max-width: 500px;
  margin: 0px auto 45px;
}
.wizzard_headers_step_wrapper {
  flex: 1 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.wizzard_component_first_step_title {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 30px;
  margin-top: -50px;
}
.wizzard_component_first_step_title span {
  font-family: var(--main-font);
  font-style: normal;
  font-weight: normal;
  font-size: 38px;
  line-height: 52px;
  text-align: center;
  color: #2b2b2b;
}
.wizzard_headers_step_count_section,
.wizzard_headers_step_title_section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.wizzard_headers_step_count_section_circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background: #bfdbff;
  position: relative;
  z-index: 1;
}
.wizzard_headers_step_count_section_circle_current {
  background: #006cff;
}
.wizzard_headers_step_count_section_circle > span {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}
.wizzard_headers_step_line {
  height: 1px;
  background-color: #f2f2f2;
  position: absolute;
  width: 100%;
  top: 15px;
  left: 50%;
}

.wizzard_headers_step_wrapper:last-child .wizzard_headers_step_line {
  display: none;
}
.wizzard_headers_step_title_section_title {
  margin-top: 7px;
}
.wizzard_headers_step_title_section_title > span {
  font-family: var(--main-font);
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 15px;
  text-align: center;
}
@media only screen and (max-width: 1000px) {
  .wizzard_component_wrapper {
    margin-top: 100px;
    padding: 0 10px;
    /* text-align: center; */
  }
}
@media only screen and (max-width: 600px) {
  .signup_quiz_sequence_selector_answers_wrapper {
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
  }
  .signup_quiz_sequence_selector_answer_card,
  .signup_quiz_sequence_selector_answer_card_small {
    margin: auto !important;
    margin-bottom: 20px !important;
  }
  .signup_quiz_sequence_selector_answers_wrapper {
    width: 100%;
  }
  .signup_quiz_single_button_wrapper,
  .signup_quiz_business_mult_buttons_wrapper {
    width: 100% !important;
  }
  .signup_quiz_business_mult_buttons_wrapper {
    justify-content: center !important;
    margin-top: 20px !important;
  }
  .signup_quiz_business_mult_buttons_wrapper .signup_quiz_button {
    margin: 0 10px;
  }
}
@media only screen and (max-width: 414px) {
  .wizzard_component_wrapper {
    padding: 20px 10px;
  }
}
