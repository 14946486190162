.mobile-ver-wraper{
    width: 100%;
    height: 100%;
}

.mobile-headr{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mobile-headr img{
    width: 90%;
    padding: 5px 0;
}

.mobile-content{
    width: 100%;

}

.mobile-content-img-wraper{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mobile-content-img-wraper img{
    width: 30%;
    padding: 20px 0;
}
.mobile-content p{
    padding: 20px;
    font-family: Roboto, sans-serif;
    color: #5d8ea3;
    text-align: center;
    font-size: 1.2em;
    font-weight: 500;
}