.signup_quiz_sequence_selector_answer_card_selected,
.signup_quiz_sequence_selector_answer_card_small_selected {
  /* background: #00bfff; */
  border: 1px solid #00bfff !important;
}
.signup_quiz_sequence_selector_answer_card_title_selected,
.signup_quiz_sequence_selector_answer_card_description_selected {
  /* color: #ffffff; */
}
.signup_quiz_sequence_selector_answer_card {
  text-align: center;
  padding: 5px 21px 21px 21px;
  width: 242px;
  height: 200px;
  border-radius: 4px;
  margin-bottom: 20px !important;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(43, 43, 43, 0.1);
  cursor: pointer;
}
.signup_quiz_sequence_selector_answer_card:first-child {
  margin-right: 50px;
}
.signup_quiz_sequence_selector_answer_card_title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
}
.signup_quiz_sequence_selector_answer_card_description {
  width: 200px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
}
.signup_quiz_sequence_selector_answer_card_small {
  display: flex;
  flex-direction: column;
  text-align: center;
  /* padding: 21px; */
  width: 137px;
  height: 134px;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(43, 43, 43, 0.1);
  cursor: pointer;
  margin: 0 21px;
}
.signup_quiz_sequence_selector_answer_card_small
  .signup_quiz_sequence_selector_answer_card_description {
  font-size: 15px;
  margin-top: 15px;
  position: relative;
  top: 12px;
}
