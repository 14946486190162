.app_main_component_container {
  display: flex;
  width: 100%;
  height: 100%;
}

.app_main_component_container::-webkit-scrollbar {
  display: none;
}

.app_main_component_content {
  flex: 1;
  width: 100%;
}

.app_main_component_content::-webkit-scrollbar {
  display: none;
}
@keyframes rightLeft{
  from {
    left: 50px;
  }
  to {
    left: 45px;
  }
}

@keyframes leftRight{
  from {
    left: 0;
  }
  to {
    left: 5px;
  }
}

.hide_navbar_left {
  position: absolute;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 7;
  border-radius: 50%;
  top: 50%;

  animation-name: rightLeft;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}



.hide_navbar_right {
  display: none;
}

.hide_navbar_left:hover {
  background: black;
  z-index: 7;
}

@media(min-width: 414px) and (max-width: 768px) {
  .app_navbar_main_container {
    z-index: 8;
  }
  .hide_navbar_right {
    position: absolute;
    top: 20px;
    background: linear-gradient(180deg, #006CFF 0%, #00BFFF 100%);
    left: 90px !important;
    z-index: 7;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    height: 45px;
    width: 45px;
    padding: 0;
  }
}

@media(max-width: 414px) {
  .app_navbar_main_container {
    z-index: 8;
  }
  .app_main_component_container {
    padding: 0px 0px 50px 0px;
  }
  .hide_navbar_right {
    position: absolute;
    top: 20px;
    background: linear-gradient(180deg, #006CFF 0%, #00BFFF 100%);
    left: 90px !important;
    z-index: 7;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    height: 45px;
    width: 45px;
    padding: 0;
  }
}