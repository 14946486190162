.signup_component_v2 {
  display: flex !important;
  flex-direction: column !important;
}

.signup_component_v2 header {
  /* width: 100%; */
  /* height: 63px; */
  display: flex;
  align-items: center;
  margin-top: 21px;
  margin-left: 70px;
}

.signup_component_v2 header img {
  height: 53px;
}
