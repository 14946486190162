.signup_quiz_business_mult_buttons_wrapper {
  margin-top: 32px;
  display: flex;
  justify-content: center;
}
.signup_quiz_component_business_sequence_step_2,
.signup_quiz_sequence_selector_answers_business_sequence_step_2_wrapper {
  width: 100%;
}

.signup_quiz_sequence_selector_answers_business_sequence_step_2_wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.signup_quiz_sequence_selector_answers_business_sequence_step_2_wrapper
  .signup_quiz_sequence_selector_answer_card:first-child {
  margin: 0;
}
.signup_quiz_sequence_selector_answers_business_sequence_step_2_wrapper
  .signup_quiz_sequence_selector_answer_card
  .signup_quiz_sequence_selector_answer_card_image_wrapper {
  width: 102px;
  height: 102px;
  margin: auto;
  position: relative;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.signup_quiz_sequence_selector_answer_card_image_wrapper img {
  width: 100%;
  height: 100%;
}

.signup_quiz_sequence_selector_answers_business_sequence_step_2_wrapper
  .signup_quiz_sequence_selector_answer_card
  .signup_quiz_sequence_selector_answer_card_description_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75px;
}
.signup_quiz_sequence_industry_selector_answers_wrapper {
  display: block;
  width: 466px;
}
.which_industry_input {
  width: 466px;
  margin-top: 27px;
  padding: 12px;
  font-style: normal;
  font-size: 20px;
  line-height: 27px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 4px;
}
.which_industry_select {
  width: 466px;
  /* padding: 12px; */
  font-style: normal;
  font-size: 18px;
  line-height: 27px;
  background: #ffffff;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.54);
  height: 53px;
}
.which_industry_select .select_main_option:hover {
  color: #006cff;
  box-shadow: none;
  background: #ffffff;
}
.which_industry_select .select_main_name span {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 16px;
  /* identical to box height, or 89% */
}
.which_industry_select .select_main_option {
  font-style: normal;
  font-size: 18px;
  line-height: 27px;
  background: #ffffff;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.54);
  height: 53px;
  padding: 15px;
}
.which_industry_select .select_main_content {
  top: 52px !important;
}
