.centerClass {
  display: flex;
  justify-content: center;
}

.codeMark {
  display: flex;
  background: lightgrey;
  align-items: center;
  justify-content: center;
  padding: 20px 10px;
  border-radius: 5px;
  font-family: var(--main-font);
  font-weight: 200;
  font-size: 11px;
  color: #000080;
  max-width: 350px;
  white-space: pre-line;
  overflow: hidden;
}

.iu_wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.codeheader {
  position: absolute;
  right: 0;
  top: -3px;
}
