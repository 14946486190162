.ToggleComponent_main {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 16px;
}

.ToggleComponent_main_checkbox {
  opacity: 0;
  width: 0;
  height: 0;
}

.ToggleComponent_main_span {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  transition: all 0.4s;
  border-radius: 12px;
}

.ToggleComponent_main_span:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: all 0.4s;
  border-radius: 50%;
}

.ToggleComponent_main_checkbox:checked + .ToggleComponent_main_span {
  background-color: #006cff;
}

.ToggleComponent_main_checkbox:checked + .ToggleComponent_main_span:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}
