.CreateNewAccount {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 36px;
}

.CreateNewAccount_container {
  width: 100%;
  display: flex;
  max-width: 412px;
  min-width: 300px;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.CreateNewAccount_Header {
  font-family: var(--main-font);
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 33px;
  margin: 0px 0px 7px 0px;
}

.CreateNewAccount_Link {
  font-size: 14px;
  text-align: center;
  color: rgba(43, 43, 43, 0.45);
  margin: 0px 0px 31px 0px;
}

.CreateNewAccount_Email {
  width: 100%;
  min-width: 300px;
  position: relative;
  margin: 0px 0px 30px 0px;
}

.CreateNewAccount_Email input {
  width: 100%;
  height: 38px;
  border: 1px solid rgba(43, 43, 43, 0.1);
  border-radius: 4px;
  padding: 9px 12px 9px 12px;
}

.CreateNewAccount_Email input::placeholder {
  font-size: 18px;
  font-weight: 300;
}

.CreateNewAccount_EmailLabel {
  position: absolute;
  background: white;
  left: 10px;
  top: -12px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  padding: 3px;
  line-height: 16px;
}

.CreateNewAccount_Name {
  display: flex;
  position: relative;
  width: 100%;
  margin: 0px 0px 30px 0px;
  justify-content: space-between;
}

.CreateNewAccount_FirstName {
  position: relative;
}

.CreateNewAccount_LastName {
  position: relative;
}

.CreateNewAccount_FirstName input {
  width: 175px;
  height: 38px;
  padding: 9px 12px 9px 12px;
  border: 1px solid rgba(43, 43, 43, 0.1);
  border-radius: 4px;
}

.CreateNewAccount_LastName input {
  width: 175px;
  height: 38px;
  padding: 9px 12px 9px 12px;
  border: 1px solid rgba(43, 43, 43, 0.1);
  border-radius: 4px;
}

.CreateNewAccount_LastName input::placeholder {
  font-size: 18px;
  font-weight: 300;
}

.CreateNewAccount_FirstName input::placeholder {
  font-size: 18px;
  font-weight: 300;
}

.CreateNewAccount_FirstNameLabel {
  position: absolute;
  background: white;
  left: 10px;
  top: -12px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  padding: 3px;
  line-height: 16px;
}

.CreateNewAccount_LastNameLabel {
  position: absolute;
  background: white;
  left: 10px;
  top: -12px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  padding: 3px;
  line-height: 16px;
}

.CreateNewAccount_Password {
  width: 100%;
  min-width: 300px;
  margin: 0px 0px 30px 0px;
}

.CreateNewAccount_CreatePassword {
  position: relative;
  width: 100%;
}

.CreateNewAccount_CreatePassword input {
  width: 100%;
  height: 38px;
  padding: 9px 12px 9px 12px;
  border: 1px solid rgba(43, 43, 43, 0.1);
  border-radius: 4px;
}

.CreateNewAccount_CreatePassword input::placeholder {
  font-size: 18px;
  font-weight: 300;
}

.CreateNewAccount_CreatePasswordLabel {
  position: absolute;
  background: white;
  left: 10px;
  top: -12px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  padding: 3px;
  line-height: 16px;
}

.CreateNewAccount_ShowPassword {
  position: absolute;
  cursor: pointer;
  width: 20px;
  height: 20px;
  right: 10px;
  top: 10px;
}

.Password_signup_quiz_v2 {
  width: 100%;
  position: relative;
}

.CreateNewAccount_PasswordValidation_hide {
  position: absolute;
  visibility: hidden;
}

.CreateNewAccount_PasswordValidation_show {
  position: absolute;
  display: flex;
  visibility: visible;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  flex-direction: column;
  justify-items: normal;
  width: fit-content;
  margin: 0px 0px 22px 0px;
  top: -100%;
  padding: 10px 10px 5px 10px;
  border-radius: 10px;
  right: 105%;
  border-left: 15px solid rgb(255, 111, 97);
  transition: 0.2s all;
}

.password_valid {
  border-left: 15px solid rgb(121, 204, 102);
}

.CreateNewAccount_PasswordValidation_show::after {
  content: " ";
  position: absolute;
  top: 50%; /* At the bottom of the tooltip */
  left: 100%;
  margin-left: 0px;
  border-width: 5px;
  border-style: solid;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-color: transparent transparent transparent white;
}

.Validation_Checkbox {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.54);
  border-radius: 2px;
  width: 15px;
  height: 15px;
  margin: 0px 10px 8px 0px;
}

.Validation_Checkbox_Confirmed {
  background: rgb(121, 204, 102);
  border-radius: 2px;
  width: 15px;
  height: 15px;
  margin: 0px 10px 8px 0px;
}

.Validation_Container {
  display: flex;
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  color: #2b2b2b;
  margin: 0px 5px;
}

.CreateNewAccount_ConfirmPassword {
  width: 100%;
  min-width: 300px;
  position: relative;
  margin: 0px 0px 30px 0px;
}

.CreateNewAccount_ConfirmPassword input {
  width: 100%;
  height: 38px;
  padding: 9px 12px 9px 12px;
  border: 1px solid rgba(43, 43, 43, 0.1);
  border-radius: 4px;
}

.CreateNewAccount_ConfirmPasswordLabel {
  position: absolute;
  background: white;
  left: 10px;
  top: -12px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  padding: 3px;
  line-height: 16px;
}

.CreateNewAccount_ConfirmPassword input::placeholder {
  font-size: 18px;
  font-weight: 300;
}

.CreateNewAccount_CheckboxContainer {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  padding: 0px 20px 0px 0px;
  align-items: center;
  font-size: 14px;
  line-height: 19px;
  color: #2b2b2b;
  margin: 0px 0px 30px 0px;
}

.CreateNewAccount_CheckboxContainer p {
  margin: 0px 0px 0px 10px;
  font-size: 14px;
  line-height: 19px;
  color: #2b2b2b;
}

#Agreement_link {
  color: inherit;
}
.CreateNewAccount input {
  font-family: var(--main-font);
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 25px;
}
.signup_loading {
  position: fixed;
  z-index: 99999;
}
.signup_quiz_v2 {
  width: 100%;
}
.signup_quiz_v2_name {
  width: 175px;
}

@media (max-width: 768px) {
  .CreateNewAccount_PasswordValidation_show::after {
    content: " ";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    border-color: #ffffff transparent transparent transparent;
  }
  .CreateNewAccount_PasswordValidation_show {
    position: absolute;
    right: 0;
    top: -150px;
    z-index: 8;
    background-color: #ffffff;
  }
}

@media only screen and (max-width: 412px) {
  .CreateNewAccount_FirstName input {
    width: 100%;
    min-width: 300px;
    height: 38px;
    padding: 9px 12px 9px 12px;
    border: 1px solid rgba(43, 43, 43, 0.1);
    border-radius: 4px;
    margin-bottom: 30px;
  }

  .CreateNewAccount_LastName input {
    width: 100%;
    min-width: 300px;
    height: 38px;
    padding: 9px 12px 9px 12px;
    border: 1px solid rgba(43, 43, 43, 0.1);
    border-radius: 4px;
  }
  .signup_quiz_v2_name {
    width: 100%;
  }
  .CreateNewAccount_Name {
    display: flex;
    flex-direction: column;
    /* position: relative; */
    width: 100%;
    min-width: 300px;
  }
  .CreateNewAccount_PasswordValidation {
    display: block;
    width: 100;
    margin-bottom: 30px;
    height: auto;
  }
  .wizzard_component_first_step_title {
    margin-top: 200px;
  }
  .signup_component_v2 header {
    margin: 20px auto !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (max-width: 1000px) {
  .wizzard_component_first_step_title {
    margin-top: 100px;
  }
}
