.GeneralTogle_main {
  border-radius: 10px;
  border: 1px solid #f2f2f2;
  background-color: #ffffff;
  padding: 20px;
  overflow: hidden;
  margin-bottom: 20px;
}

.GeneralTogle_header_content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}

.GeneralTogle_header_content span {
  margin-left: 20px;
  margin-right: 10px;
  color: #212121;
  font-family: var(--main-font);
  font-size: 14px;
  font-weight: 500;
}

.GeneralTogle_header_content_own {
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
}

.GeneralTogle_header_content_own .bot_avatar_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  background-color: #f2f2f2;
}

.GeneralTogle_header {
  height: 40px;
  display: flex;
  align-items: center;
}

.GeneralTogle_header_content_own .bot_avatar_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  background-color: #f2f2f2;
}

.GeneralTogle_content {
  overflow: hidden;
  transition: all 0.3s ease 0s;
}

@media(max-width: 414px) {
  .GeneralTogle_main {
    padding: 5px;
  }
  .GeneralTogle_content {
    overflow: auto;
  }
}
