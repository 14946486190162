.bt-component {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    min-width: 53px;
}

.bt-span {
    display: flex;

    justify-content: center;
    text-align: center;
    font-family: var(--main-font);
    font-size: 14px;
    font-weight: 600;
    align-items: center;
}

.bt-span svg {
    margin-right: 5px;
}

.bt-span img {
    margin-right: 10px;
}

.loader {
    width: 10px;
    height: 10px;
    background: var(--color-loader);
    border-radius: 50%;
    animation: button_loader 0.7s 0.15s ease alternate infinite;
}

.loader:after,
.loader:before {
    content: '';
    position: absolute;
    background: var(--color-loader);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    animation: button_loader 0.7s ease alternate infinite;
}

@keyframes button_loader {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

.loader:before {
    left: -20px;
    /* animation-delay: 0.1s; */
}

.loader:after {
    right: -20px;
    animation-delay: 0.3s;
}

.button_acuikit {
    height: 40px;
    min-width: 120px;
    justify-content: center;
    padding: 0 15px;
    background: #006cff;
    border-radius: 4px;
}

.button_acuikit span {
    color: white;
}

.button_acuikit:hover {
    background: #0060e5;
}

.button_acuikit_outline {
    background: white;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.button_acuikit_outline:hover {
    background: rgba(0, 108, 255, 0.1);
    border: 1px solid rgba(0, 0, 0, 0);
}

.button_acuikit_outline span {
    color: #006cff;
}

.button_acuikit_red {
    background: #ff6f61;
}

.button_acuikit_red:hover {
    background: #ff5747;
}
.button_acuikit_locked {
    background: rgba(0, 0, 0, 0.1);
    cursor: default;
    cursor: not-allowed;
}
.button_acuikit_locked span {
    color: rgba(0, 0, 0, 0.54);
}
.button_acuikit_locked:hover {
    background: rgba(0, 0, 0, 0.1);
}

.button_acuikit_locked_outline {
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: white;
}
.button_acuikit_locked_outline:hover {
    background: rgba(0, 0, 0, 0.02);
    border: 1px solid rgba(0, 0, 0, 0.1);
}
.button_acuikit_locked_outline span {
    color: rgba(0, 0, 0, 0.38);
}

.button_component_bold_text .bt-content .bt-span {
    font-weight: bold !important;
}
.button_acuikit.button_training_ai:hover {
    background-color: rgba(241, 244, 255, 0.836);
    color: rgba(241, 244, 255, 0.836);
}
